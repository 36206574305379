import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, ListGroup, ListGroupItem, Media, Row } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { FoodCalorieText, CreateFoodText, FoodNameText, FoodIngredientsText, FoodProteinText, FoodCookingStepsText } from '../../../Constant';
import { Bounce, toast } from 'react-toastify';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper'
import { addFoodServiceApi } from '../../../Services/Api.Service';

const AddFoodWidgets = () => {
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    foodName: '',
    calorie: '',
    protein: '',
    ingredients: [],
    cookingSteps: [],
  });

  const [isValid, setIsValid] = useState({
    foodName: false,
    calorie: false,
    protein: false,
    ingredient: false,
    cookingStep: false,
  });

  const [isTouched, setIsTouched] = useState({
    foodName: false,
    calorie: false,
    protein: false,
    ingredient: false,
    cookingStep: false,
  });

  const [inputMultiple, setInputMultiple] = useState({
    ingredient: '',
    cookingStep: ''
  });

  const [editIndexIngredient, setEditIndexIngredient] = useState(null);
  const [editValueIngredient, setEditValueIngredient] = useState('');

  const [editIndexCookingStep, setEditIndexCookingStep] = useState(null);
  const [editValueCookingStep, setEditValueCookingStep] = useState('');

  const handleChangeMultipleInput = (event) => {
    const { name, value } = event.target;
    setInputMultiple({
      ...inputMultiple,
      [name]: value
    })
    setIsValid({
      ...isValid,
      [name]: value.length > 0
    });

    setIsTouched({
      ...isTouched,
      [name]: value.length > 0
    });
  };

  const handleAddIngredient = () => {
    if (inputMultiple.ingredient.trim() !== '') {
      setValues({
        ...values,
        ingredients: [...values.ingredients, inputMultiple.ingredient]
      });

      setInputMultiple({
        ...inputMultiple,
        ingredient: ''
      })

      setIsValid({
        ...isValid,
        ingredient: false
      });

      setIsTouched({
        ...isTouched,
        ingredient: false
      });
    } else {
      setIsValid({
        ...isValid,
        ingredient: false
      });

      setIsTouched({
        ...isTouched,
        ingredient: true
      });
    }
  };

  const handleEditIngredient = (index) => {
    setEditIndexIngredient(index);
    setEditValueIngredient(values.ingredients[index]);
  };

  const handleSaveEditIngredient = (index) => {
    const newIngredients = [...values.ingredients];
    newIngredients[index] = editValueIngredient;
    setValues({
      ...values,
      ingredients: newIngredients
    });
    setEditIndexIngredient(null);
    setEditValueIngredient('');
  };

  const handleDeleteIngredient = (index) => {
    const newIngredients = [...values.ingredients];
    newIngredients.splice(index, 1);
    setValues({
      ...values,
      ingredients: newIngredients
    });
    setEditIndexIngredient(null);
    setEditValueIngredient('');
  };

  const handleAddCookingStep = () => {
    if (inputMultiple.cookingStep.trim() !== '') {
      setValues({
        ...values,
        cookingSteps: [...values.cookingSteps, inputMultiple.cookingStep]
      });

      setInputMultiple({
        ...inputMultiple,
        cookingStep: ''
      })

      setIsValid({
        ...isValid,
        cookingStep: false
      });

      setIsTouched({
        ...isTouched,
        cookingStep: false
      });
    } else {
      setIsValid({
        ...isValid,
        cookingStep: false
      });

      setIsTouched({
        ...isTouched,
        cookingStep: true
      });
    }
  };

  const handleEditCookingStep = (index) => {
    setEditIndexCookingStep(index);
    setEditValueCookingStep(values.cookingSteps[index]);
  };

  const handleSaveEditCookingStep = (index) => {
    const newCookingSteps = [...values.cookingSteps];
    newCookingSteps[index] = editValueCookingStep;
    setValues({
      ...values,
      cookingSteps: newCookingSteps
    });
    setEditIndexCookingStep(null);
    setEditValueCookingStep('');
  };

  const handleDeleteCookingStep = (index) => {
    const newCookingSteps = [...values.cookingSteps];
    newCookingSteps.splice(index, 1);
    setValues({
      ...values,
      cookingSteps: newCookingSteps
    });
    setEditIndexCookingStep(null);
    setEditValueCookingStep('');
  };

  const moveIngredient = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = values.ingredients[dragIndex]
      setValues({
        ...values,
        ingredients: update(values.ingredients, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        }),
      }
      )
    },
    [values]
  );

  const moveCookingStep = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = values.cookingSteps[dragIndex]
      setValues({
        ...values,
        cookingSteps: update(values.cookingSteps, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        }),
      }
      )
    },
    [values]
  );

  const handleChangeSingleInput = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });

    setIsValid({
      ...isValid,
      [name]: value.length > 0
    });

    setIsTouched({
      ...isTouched,
      [name]: true
    });
  };

  const createFood = async (e) => {
    if (isValid.foodName && isValid.calorie && isValid.protein && values.ingredients.length > 0 && values.cookingSteps.length > 0) {
      setLoading(true);
      try {
        await addFoodServiceApi(values)
        .then((resp) => {
          switch (resp.data['statusCode']) {
            case 200:
              toast.success('Food added successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });

              setValues({
                foodName: '',
                calorie: '',
                protein: '',
                ingredients: [],
                cookingSteps: []
              });

              setIsValid(
                Object.keys(isValid).reduce((acc, key) => {
                  acc[key] = false;
                  return acc;
                }, {})
              );

              setIsTouched(
                Object.keys(isTouched).reduce((acc, key) => {
                  acc[key] = false;
                  return acc;
                }, {})
              );

              break;
            default:
              throw new Error(resp);
          }
        })
      } catch (error) {
        toast.error('Error', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      setLoading(false);
    } else {
      toast.error('Please fill all', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  return (
    <Fragment>
      <DndProvider backend={HTML5Backend}>
        <Col sm="12" lg="6" xl="8" md="12" className="xl-50 box-col-6">
          <Card>
            <CardHeader>
              <Media>
                <Media body>
                  <H5>{CreateFoodText}</H5>
                </Media>
              </Media>
            </CardHeader>
            <CardBody className="contact-form">
              <Form>
                {/* <div className="form-icon"><i className="icofont icofont-envelope-open"></i></div> */}
                <Row className="theme-form e-commerce-form">
                  <FormGroup>
                    <Label for="exampleInputName">{FoodNameText}</Label>
                    <Input
                      name='foodName'
                      className="form-control"
                      id="exampleInputName"
                      type="text"
                      onChange={handleChangeSingleInput}
                      value={values.foodName}
                      required=""
                      placeholder="Nasi Goreng"
                      valid={isValid.foodName && isTouched.foodName}
                      invalid={!isValid.foodName && isTouched.foodName}
                    />
                    <FormFeedback valid>Name looks good!</FormFeedback>
                    <FormFeedback invalid={(!isValid.foodName && isTouched.foodName).toString()}>Please enter a valid name.</FormFeedback>
                  </FormGroup>
                  <FormGroup className="mb-3 col-6 p-r-0">
                    <Label className="col-form-label" htmlFor="exampleInputEmail1">{FoodCalorieText}</Label>
                    <Input
                      name='calorie'
                      className="form-control"
                      type='number'
                      rows="3"
                      cols="50"
                      onChange={handleChangeSingleInput}
                      value={values.calorie}
                      required=""
                      placeholder="123"
                      valid={isValid.calorie && isTouched.calorie}
                      invalid={!isValid.calorie && isTouched.calorie} />
                    <FormFeedback valid>Calorie looks good!</FormFeedback>
                    <FormFeedback invalid={(!isValid.calorie && isTouched.calorie).toString()}>Please enter a valid calorie.</FormFeedback>
                  </FormGroup>
                  <FormGroup className="mb-3 col-6">
                    <Label className="col-form-label" htmlFor="exampleInputEmail1">{FoodProteinText}</Label>
                    <Input
                      name='protein'
                      className="form-control"
                      id="exampleInputEmail1"
                      type="number"
                      onChange={handleChangeSingleInput}
                      value={values.protein}
                      required=""
                      placeholder="123"
                      valid={isValid.protein && isTouched.protein}
                      invalid={!isValid.protein && isTouched.protein} />
                    <FormFeedback valid>Protein looks good!</FormFeedback>
                    <FormFeedback invalid={(!isValid.protein && isTouched.protein).toString()}>Please enter a valid protein.</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                  <Label className="col-form-label" htmlFor="exampleInputEmail1">{FoodIngredientsText}</Label>
                  <ListGroup>
                    {values.ingredients.map((item, index) => (
                      <DraggableListGroupItem
                        index={index}
                        editIndexIngredient={editIndexIngredient}
                        editValueIngredient={editValueIngredient}
                        setEditValueIngredient={setEditValueIngredient}
                        item={item}
                        handleSaveEditIngredient={handleSaveEditIngredient}
                        setEditIndexIngredient={setEditIndexIngredient}
                        handleEditIngredient={handleEditIngredient}
                        handleDeleteIngredient={handleDeleteIngredient}
                        moveItem={moveIngredient}
                      />
                      // DraggableListGroupItem(index, editIndexIngredient, editValueIngredient, setEditValueIngredient, item, handleSaveEditIngredient, setEditIndexIngredient, handleEditIngredient, handleDeleteIngredient)
                    ))}
                  </ListGroup>
                  <Input
                    name='ingredient'
                    className="form-control"
                    id="exampleInputEmail1"
                    type="text"
                    onChange={handleChangeMultipleInput}
                    value={inputMultiple.ingredient}
                    required=""
                    placeholder="Nasi"
                    valid={isValid.ingredient && isTouched.ingredient}
                    invalid={!isValid.ingredient && isTouched.ingredient} />
                  <FormFeedback valid>Ingredient looks good!</FormFeedback>
                  <FormFeedback invalid={(!isValid.ingredient && isTouched.ingredient).toString()}>Please enter a valid ingredients.</FormFeedback>
                  <Button className='mt-2' color="secondary" onClick={handleAddIngredient}>Add Ingredient</Button>
                </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label" htmlFor="exampleInputEmail1">{FoodCookingStepsText}</Label>
                    <ListGroup>
                      {values.cookingSteps.map((item, index) => (
                        <DraggableListGroupItem
                          index={index}
                          editIndexIngredient={editIndexCookingStep}
                          editValueIngredient={editValueCookingStep}
                          setEditValueIngredient={setEditValueCookingStep}
                          item={item}
                          handleSaveEditIngredient={handleSaveEditCookingStep}
                          setEditIndexIngredient={setEditIndexCookingStep}
                          handleEditIngredient={handleEditCookingStep}
                          handleDeleteIngredient={handleDeleteCookingStep}
                          moveItem={moveCookingStep}
                        />
                      ))}
                    </ListGroup>
                    <Input
                      name='cookingStep'
                      className="form-control"
                      id="exampleInputEmail1"
                      type="text"
                      onChange={handleChangeMultipleInput}
                      value={inputMultiple.cookingStep}
                      required=""
                      placeholder="Nasi"
                      valid={isValid.cookingStep && isTouched.cookingStep}
                      invalid={!isValid.cookingStep && isTouched.cookingStep} />
                    <FormFeedback valid>Ingredient looks good!</FormFeedback>
                    <FormFeedback invalid={(!isValid.cookingStep && isTouched.cookingStep).toString()}>Please enter a valid ingredients.</FormFeedback>
                    <Button className='mt-2' color="secondary" onClick={handleAddCookingStep}>Add Ingredient</Button>
                  </FormGroup>
                  <div className="text-sm-end">
                    <Btn attrBtn={{ color: 'primary', onClick: (e) => createFood(e), disabled: loading ? loading : loading, }}>{loading ? 'LOADING...' : CreateFoodText}</Btn>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </DndProvider>
    </Fragment>
  );
};

export default AddFoodWidgets;

const style = {
  // border: '1px dashed gray',
  // padding: '0.5rem 1rem',
  // marginBottom: '.5rem',
  // backgroundColor: 'white',
  cursor: 'move',
}

const DraggableListGroupItem = ({ index, editIndexIngredient, editValueIngredient, setEditValueIngredient, item, handleSaveEditIngredient, setEditIndexIngredient, handleEditIngredient, handleDeleteIngredient, moveItem }) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: "item",
    hover(itemK, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = itemK.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      itemK.index = hoverIndex
    },
  })
  const [, drag] = useDrag({
    type: 'item',
    item: {
      type: "item",
      // id, 
      index
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drag(drop(ref))
  return (
    <div key={index} ref={ref} style={{ ...style }}>
      <ListGroupItem key={index} className="d-flex justify-content-between">
        <div className="d-flex flex-row align-items-center">
          <span>{index + 1}.</span>
          {editIndexIngredient === index ? (
            <Input
              type="text"
              value={editValueIngredient}
              onChange={(e) => setEditValueIngredient(e.target.value)}
            />
          ) : (
            <span>&nbsp;{item}</span>
          )}
        </div>
        <ButtonGroup className='my-auto' size="sm">
          {editIndexIngredient === index ? (
            <>
              <Button color="success" onClick={() => handleSaveEditIngredient(index)} size="sm">Save</Button>
              <Button color="secondary" onClick={() => setEditIndexIngredient(null)} size="sm">Cancel</Button>
            </>
          ) : (
            <>
              <Button color="primary" onClick={() => handleEditIngredient(index)} size="sm">Edit</Button>
              <Button color="danger" onClick={() => handleDeleteIngredient(index)} size="sm">Delete</Button>
            </>
          )}
        </ButtonGroup>
      </ListGroupItem>
    </div>
  )
  // const [, drag] = useDrag({
  //   type: 'item',
  //   item: { index },
  // });

  // const [, drop] = useDrop({
  //   accept: 'item',
  //   hover: (draggedItem) => {
  //     if (draggedItem.index !== index) {
  //       moveItem(draggedItem.index, index);
  //       draggedItem.index = index;
  //     }
  //   },
  // });

  // return <div ref={(node) => drag(drop(node))} style={{ cursor: 'move' }}>
  //   <ListGroupItem key={index} className="d-flex justify-content-between">
  //     <div className="d-flex flex-row align-items-center">
  //       <span>{index + 1}.</span>
  //       {editIndexIngredient === index ? (
  //         <Input
  //           type="text"
  //           value={editValueIngredient}
  //           onChange={(e) => setEditValueIngredient(e.target.value)}
  //         />
  //       ) : (
  //         <span>&nbsp;{item}</span>
  //       )}
  //     </div>
  //     <ButtonGroup className='my-auto' size="sm">
  //       {editIndexIngredient === index ? (
  //         <>
  //           <Button color="success" onClick={() => handleSaveEditIngredient(index)} size="sm">Save</Button>
  //           <Button color="secondary" onClick={() => setEditIndexIngredient(null)} size="sm">Cancel</Button>
  //         </>
  //       ) : (
  //         <>
  //           <Button color="primary" onClick={() => handleEditIngredient(index)} size="sm">Edit</Button>
  //           <Button color="danger" onClick={() => handleDeleteIngredient(index)} size="sm">Delete</Button>
  //         </>
  //       )}
  //     </ButtonGroup>
  //   </ListGroupItem>
  // </div>
}
