import React, { Fragment } from 'react';
import { LogIn, Settings, User } from 'react-feather';
import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image, LI, UL } from '../../AbstractElements';
import MaxMiniSize from './MaxMiniSize';
import { Account, LogOut } from '../../Constant';
import man from '../../assets/images/dashboard/1.png';
import { logoutServiceApi } from '../../Services/Api.Service';

const HeaderContain = () => {
  const lastName = localStorage.getItem('lastName');
  const photoUrl = localStorage.getItem('photoUrl');

  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76">
        <UL attrUL={{ className: `simple-list flex-row nav-menus` }}>
          <MaxMiniSize />
          <LI attrLI={{ className: 'profile-nav onhover-dropdown pe-0 pt-0 me-0' }} >
            <Media className="profile-media">
              <Image attrImage={{
                className: 'rounded-circle', 
                src: `${photoUrl ? photoUrl : man}`, 
                alt: '',
              }}
              />
              <Media body>
                <span>{lastName ? lastName : "null"}</span>
              </Media>
            </Media>
            <UL attrUL={{ className: `simple-list profile-dropdown onhover-show-div` }}>
              <LI><Link to={`${process.env.PUBLIC_URL}/users/userprofile`}><i><User /></i><span>{Account} </span></Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/users/useredit`}><i><Settings /></i><span>Settings</span></Link></LI>
              <LI attrLI={{ onClick: logoutServiceApi }}>
                <Link>
                  <LogIn />
                  <span>{LogOut}</span>
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div >
    </Fragment >
  );
};
export default HeaderContain;
