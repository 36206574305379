import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import GymContain from '../../../Component/Admin/Gyms';

const Gym = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Admin" title="Gym" />
      <GymContain />
    </Fragment>
  );
};
export default Gym;