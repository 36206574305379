import React, { Fragment } from "react";
import "./App.css";
import Routers from "./Routes";
import CartProvider from "./_helper/ecommerce/cart/CartProvider";
import FilterProvider from "./_helper/ecommerce/filter/FilterProvider";
import WishListProvider from "./_helper/ecommerce/wishlist/WishProvider";
import ProjectProvider from "./_helper/project-app/ProjectProvider";
import CustomProvider from "./_helper/customizer/CustomizerProvider";
import TableProvider from "./_helper/Table/TableProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/customizer/CustomizerProvider";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

function App() {
  return (
    <Fragment>
      <CustomizerProvider>
        <TableProvider>
          <CustomProvider>
            <WishListProvider>
              <FilterProvider>
                <CartProvider>
                  <ProjectProvider>
                    <AnimationThemeProvider>
                      <Routers />
                    </AnimationThemeProvider>
                  </ProjectProvider>
                </CartProvider>
              </FilterProvider>
            </WishListProvider>
          </CustomProvider>
        </TableProvider>
      </CustomizerProvider>
    </Fragment>
  );
}
export default App;
