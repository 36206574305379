export const CreateGymText = 'Create New Gym';
export const GymDetailText = 'Detail Gym';
export const EditGymText = 'Edit Gym';
export const GymNameText = 'Gym Name';
export const GymUploadImageText = 'Gym Image';
export const GymAddressText = 'Gym Address';
export const GymEmailText = 'Gym Email';
export const GymPasswordText = 'Gym Password';
export const GymPhoneText = 'Gym Telephone Number';
export const GymsText = 'Gyms';
export const GenerateGymCodeText = 'Generate Gym Code';
export const GenerateText = 'Generate';

export const CreateFoodText = 'Add Food';
export const FoodNameText = 'Food Name';
export const FoodCalorieText = 'Calorie';
export const FoodProteinText = 'Protein';
export const FoodIngredientsText = 'Ingredients';
export const FoodCookingStepsText = 'Cooking Steps';
export const FoodsText = 'Foods';
export const FoodDetailText = 'Detail Food';

export const UsersText = 'Users';
export const UserDetailText = 'User Detail';
export const GenderText = 'Gender';
export const AgeText = 'Age';
export const WeightText = 'Weight';
export const HeightText = 'height';
export const GymFrequencyText = 'Gym Frequency';
export const CalorieTargetText = 'Calorie Target';

export const CreateFranchiseText = 'Create Franchise';
export const NameFranchiseText = 'Name Franchise';
export const PhoneFranchiseText = 'Phone Number Franchise';
export const ImageFranchiseText = 'Image Franchise';
export const EmailFranchiseText = 'Email Franchise';
export const PasswordFranchiseText = 'Password Franchise';
export const AddressFranchiseText = 'Address Franchise';

export const Box = 'Box';
export const Apply = 'Apply';
export const UnlimitedColor = 'Unlimited Color';
export const Configuration = 'Configuration';
export const CopyText = 'Copy text';

export const RouterAnimation = 'Router Animation';
export const ZoomFade = 'Zoom Fade';
export const SildeFade = 'Silde Fade';
export const FadeBottom = 'Fade Bottom';
export const Fade = 'Fade';
export const ZoomOut = 'Zoom Out';
export const None = 'None';
export const LightLayout = 'Light layout';
export const DarkLayout = 'Dark Layout';
export const MixLayout = 'Mix Layout';

export const Cancel = 'Cancel';
export const Back = 'Back';

export const Name = 'Name';
export const Phone = 'Phone';
export const EmailAddress = 'Email Address';
export const ImgProfileText = 'Photo Profile';
export const RetypePassword = 'Retype Password';
export const NewPassword = 'New Password';
export const OldPassword = 'Old Password';
export const Password = 'Password';

export const Save = 'Save';
export const EditPassword = 'Edit Password';
export const EditProfile = 'Edit Profile';
export const UpdateProfile = 'Update Profile';
export const DeleteProfile = 'Delete Profile';
export const Account = 'Account';

export const SignIn = 'Sign In';
export const RememberMe = 'Remember Me';
export const LogOut = 'Log Out';

export const MAINTENANCE = 'MAINTENANCE';
export const BACK_TO_HOME_PAGE = 'BACK TO HOME PAGE';
export const WE_ARE_COMING_SOON = 'WE ARE COMING SOON';