import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Media } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { FoodsText } from '../../../Constant';
import DataTable from 'react-data-table-component';

const FoodList = ({ handleDetailFood, pages, loading, foodsData, handlePageChange, handlePerRowsChange }) => {

  const foodsColumns = [
    {
      name: 'Foodname',
      selector: (row) => row.name,
      sortable: true,
      maxWidth: '250px',
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      }
    },
    {
      name: 'Calorie',
      selector: (row) => row.calorie,
      sortable: true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      }
    },
    {
      name: 'Protein',
      selector: (row) => row.protein,
      sortable: true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      }
    },
  ];


  return (
    <Fragment>
      <Col xl='6' className='xl-50 box-col-6'>
        <Card className="employee-status">
          <CardHeader className="pb-0">
            <Media>
              <Media body>
                <H5>{FoodsText}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody>
            <div className="user-status table-responsive">
              <DataTable
                noHeader
                pagination={true}
                paginationServer
                paginationTotalRows={foodsData.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                columns={foodsColumns}
                data={foodsData.slice(pages.startIndex, pages.endIndex)}
                onRowClicked={handleDetailFood}
                progressPending={loading}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default FoodList;