import { WidgetsSvg } from '../../Data/svgIcons';

export const MENUITEMS = [
    {
        menutitle: 'Admin',
        Items: [
            {
                title: 'Admin', icon: WidgetsSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/admin/gyms`, title: 'Gym', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/admin/foods`, title: 'Food', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}#`, title: 'Franchises (coming soon)', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/admin/user-management`, title: 'User Management', type: 'link' },
                ]
            },
        ]
    },
];