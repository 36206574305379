import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import FoodList from './FoodList';
import AddFoodWidgets from './AddFoodWidgets';
import { handleDisplayPages, handleIndexData } from '../../../_helper/Pages';
import { getAllFoodServiceApi } from '../../../Services/Api.Service';
import { toast } from 'react-toastify';
import DetailFood from './DetailFood';

const FoodContain = () => {
  const [loading, setLoading] = useState(true);
  
  const [food, setFood] = useState(null);
  const [foodsData, setFoodsData] = useState([]);
  
  const [pageSize, setPageSize] = useState(10);

  
  const [pages, setPages] = useState({
    total: 0,
    active: 1,
    pageSize: 10,
    pagesToShow: 5,
    startPage: 0,
    endPage: 0,
    startIndex: 0,
    endIndex: 0
  });

  const getAllFood = async () => {
    try {
      await getAllFoodServiceApi()
        .then(async (resp) => {
          switch (resp.data['statusCode']) {
            case 200:
              setFoodsData(
                resp.data['data'].map(food => ({
                  id: food.ID,
                  name: food.Nama,
                  type: food.Jenis,
                  ingredient: food.Bahan,
                  cookingStep: food.CookingStep,
                  calorie: food.Kalori,
                  protein: food.Protein,
                  image: food.Foto
                }))
              );
              const totalPages = Math.ceil(resp.data['data'].length / pages.pageSize)
              const [startPage, endPage] = handleDisplayPages(1, pages.pagesToShow, totalPages);
              const [startIndex, endIndex] = handleIndexData(pages.active, pages.pageSize);
              setPages({
                ...pages,
                total: totalPages,
                startPage: startPage,
                endPage: endPage,
                startIndex: startIndex,
                endIndex: endIndex
              });
              setLoading(false);
              break;
            default:
              throw new Error(resp);
          }
        });
    } catch (error) {
      setLoading(false);
      toast.error('Gagal mendapatkan data, silahkan refresh.');
    }
  }

  useEffect(() => {
    getAllFood();
  }, [])

  useEffect(() => {
    getAllFood();
  }, [pageSize])

  const handlePageChange = page => {
    const [startIndex, endIndex] = handleIndexData(page, pageSize);

    setPages({
      ...pages,
      startIndex: startIndex,
      endIndex: endIndex
    });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    const [startIndex, endIndex] = handleIndexData(page, pageSize);

    setPages({
      ...pages,
      startIndex: startIndex,
      endIndex: endIndex,
      pageSize: newPerPage
    });
    setPageSize(newPerPage);
  }

  const handleDetailFood = (food) => {
    setFood(food);
  }
  return (
    <Fragment>
      <Container fluid={true} className="default-dash">
        <Row>
          <FoodList
            handleDetailFood={handleDetailFood}
            pages={pages}
            loading={loading}
            foodsData={foodsData}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
          />
          <DetailFood
            food={food}
          />
          <AddFoodWidgets/>
        </Row>
      </Container>
    </Fragment>
  );
};
export default FoodContain;