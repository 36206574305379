import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import LoginTab from './Tabs/LoginTab';

const Logins = () => {
  const selected = useState('jwt');

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main1 login-tab1">
                    <LoginTab selected={selected} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;