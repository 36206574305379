import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Media } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { GymsText } from '../../../Constant';
import DataTable from 'react-data-table-component';

const GymList = ({ handleDetailGym, gymsData, handlePageChange, handlePerRowsChange, pages, loading }) => {
  const gymsColumns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      maxWidth: '250px',
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      }
    },
    {
      name: 'Address',
      selector: (row) => row.address,
      sortable: true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      }
    },
  ];

  return (
    <Fragment>
      <Col xl='6' className='xl-50 box-col-6'>
        <Card className="employee-status">
          <CardHeader className="pb-0">
            <Media>
              <Media body>
                <H5>{GymsText}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody>
            <div className="table-responsive product-table">
              <DataTable
                noHeader
                pagination={true}
                paginationServer
                paginationTotalRows={gymsData.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                columns={gymsColumns}
                data={gymsData.slice(pages.startIndex, pages.endIndex)}
                onRowClicked={handleDetailGym}
                progressPending={loading}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default GymList;