import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Media } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { UsersText } from '../../../Constant';
import DataTable from 'react-data-table-component';
import man from '../../../assets/images/dashboard/1.png';

const UserList = ({ handleDetailUser, pages, loading, usersData, handlePageChange, handlePerRowsChange }) => {

  const usersColumns = [
    {
      name: '',
      selector: (row) => <img 
        src={row.urlPhoto ? row.urlPhoto : man} 
        alt={row.name} 
        className='rounded-circle object-fit-cover' 
        style={{ height: '40px', width: '40px' }} 
      />
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      minWidth: '200px',
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      minWidth: '200px',
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
    {
      name: 'Gender',
      selector: (row) => row.gender ? 'Perempuan' : 'Laki-Laki',
      sortable: true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
    {
      name: 'Age',
      selector: (row) => row.age,
      sortable: true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
    {
      name: 'Weight',
      selector: (row) => row.weight,
      sortable: true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
    {
      name: 'height',
      selector: (row) => row.height,
      sortable: true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
    {
      name: 'Gym Frequency',
      selector: (row) => row.gymFrequency,
      sortable: true,
      minWidth: '150px',
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
    {
      name: 'Calorie Target',
      selector: (row) => row.calorieTarget,
      sortable: true,
      minWidth: '150px',
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
    {
      name: 'Phone Number',
      selector: (row) => row.phoneNumber,
      sortable: true,
      minWidth: '150px',
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      wrap: true,
    },
  ];


  return (
    <Fragment>
      <Col xl='6' className='xl-50 box-col-6'>
        <Card className="employee-status">
          <CardHeader className="pb-0">
            <Media>
              <Media body>
                <H5>{UsersText}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody>
            <div className="user-status table-responsive">
              <DataTable
                noHeader
                pagination={true}
                paginationServer
                paginationTotalRows={usersData.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                columns={usersColumns}
                data={usersData.slice(pages.startIndex, pages.endIndex)}
                onRowClicked={handleDetailUser}
                progressPending={loading}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default UserList;