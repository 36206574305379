import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import FoodContain from '../../../Component/Admin/Foods';

const Food = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Admin" title="Food" />
      <FoodContain />
    </Fragment>
  );
};
export default Food;