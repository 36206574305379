import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Media, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { AgeText, CalorieTargetText, Cancel, DeleteProfile, EditProfile, EmailAddress, GenderText, GymFrequencyText, HeightText, ImgProfileText, Name, Phone, Save, UserDetailText, WeightText } from '../../../Constant';
import { deleteUserServiceApi, updateUserServiceApi } from '../../../Services/Api.Service';
import { toast } from 'react-toastify';
import man from '../../../assets/images/dashboard/1.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const DetailUser = ({ user, getAllUser }) => {
  const [editUserData, setEditUserData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenPhoto, setIsOpenPhoto] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const [isValid, setIsValid] = useState({
    name: false,
    email: false,
    gender: false,
    age: false,
    weight: false,
    height: false,
    gymFrequency: false,
    calorieTarget: false,
    imageFile: false,
    imageName: false,
    phoneNumber: false,
  });

  const [isTouched, setIsTouched] = useState({
    name: false,
    email: false,
    gender: false,
    age: false,
    weight: false,
    height: false,
    gymFrequency: false,
    calorieTarget: false,
    imageFile: false,
    imageName: false,
    phoneNumber: false,
  });

  const handleDeleteUser = async () => {
    if (user) {
      setLoading(true);
      try {
        await deleteUserServiceApi(user.id)
          .then(async (resp) => {
            switch (resp.data['statusCode']) {
              case 200:
                setLoading(false);
                toast.success('Delete success');
                getAllUser();
                break;
              default:
                setLoading(false);
                throw new Error(resp);
            }
          });
      } catch (error) {
        setLoading(false);
        toast.error('Gagal delete data, silahkan refresh.');
      }
    } else {
      toast.error('Pilih user dahulu');
    }
  }

  const handleUpdateUser = async () => {
    setLoading(true);
    if (editUserData) {
      try {
        await updateUserServiceApi(user.id, editUserData)
          .then(async (resp) => {
            switch (resp.data['statusCode']) {
              case 200:
                setLoading(false);
                toast.success('Update user success');
                getAllUser();
                setIsEdit(false);
                setEditUserData(false);

                setIsValid(
                  Object.keys(isValid).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                  }, {})
                );
  
                setIsTouched(
                  Object.keys(isTouched).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                  }, {})
                );

                break;
              default:
                throw new Error(resp);
            }
          });
      } catch (error) {
        console.log(error);
        toast.error('Gagal ubah data, silahkan hubungi cs.');
      }
    } else {
      toast.error('Tidak ada yang di ubah');
    }
    setLoading(false);
  }

  const handleCancelEdit = () => {
    setEditUserData(false);
    setIsEdit(false)
  }

  const handleFileChange = (event) => {
    setEditUserData({
      ...editUserData,
      imageFile: event.target.files[0],
      imageName: event.target.files[0].name,
      imageUrl: URL.createObjectURL(event.target.files[0])
    });

    setIsTouched({
      ...isTouched,
      imageFile: true,
      imageName: true
    });

    setIsValid({
      ...isValid,
      imageFile: true,
      imageName: true
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditUserData({
      ...editUserData,
      [name]: value
    });

    setIsValid({
      ...isValid,
      [name]: value.length > 0 
    });

    setIsTouched({
      ...isTouched,
      [name]: true
    });
  }

  return (
    <Fragment>
      <Col xl='6' className='xl-50 box-col-6'>
        <Card>
          <CardHeader>
            <Media>
              <Media body>
                <H5>{UserDetailText}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="contact-form">
            <Form className="theme-form">
              <div className="form-icon"><i className="icofont icofont-envelope-open"></i></div>
              {
                isEdit && <FormGroup>
                  <Label for="exampleInputName">{ImgProfileText}</Label>
                  <Input
                    name='imageFile'
                    className="form-control"
                    id="exampleInputName"
                    type="file"
                    onChange={handleFileChange}
                    valid={isValid.imageFile && isTouched.imageFile}
                    invalid={!isValid.imageFile && isTouched.imageFile}
                  />
                  <FormFeedback valid>Image looks good!</FormFeedback>
                  <FormFeedback invalid={(!isValid.imageFile && isTouched.imageFile).toString()}>Please enter a valid image.</FormFeedback>
                </FormGroup>
              }
              <img
                src={editUserData?.imageUrl ?? (user?.urlPhoto !== '' ? user?.urlPhoto : null) ?? man}
                alt={user && user.name}
                className='rounded mb-3'
                style={{ height: '150px', cursor: 'pointer' }}
                onClick={() => setIsOpenPhoto(true)}
              />
              <FormGroup>
                <Label for="exampleInputName">{Name}</Label>
                <Input
                  name='name'
                  className="form-control"
                  id="exampleInputName"
                  type="text"
                  onChange={handleChange}
                  value={editUserData.name ?? user?.name ?? ''}
                  required=""
                  disabled={!isEdit}
                  valid={isValid.name && isTouched.name}
                  invalid={!isValid.name && isTouched.name}
                />
                <FormFeedback valid>Name looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.name && isTouched.name).toString()}>Please enter a valid name.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{EmailAddress}</Label>
                <Input
                  name='email'
                  className="form-control"
                  type='text'
                  onChange={handleChange}
                  value={editUserData.email ?? user?.email ?? ''}
                  required=""
                  disabled={!isEdit}
                  valid={isValid.email && isTouched.email}
                  invalid={!isValid.email && isTouched.email}
                />
                <FormFeedback valid>Email looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.email && isTouched.email).toString()}>Please enter a valid email.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{GenderText}</Label>
                <Input
                  type="select"
                  name="gender"
                  className="form-control digits"
                  disabled={!isEdit}
                  onChange={handleChange}
                  value={editUserData.gender ?? user?.gender ?? 0}
                >
                  <option value={0}>{'Laki-Laki'}</option>
                  <option value={1}>{'Perempuan'}</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{AgeText}</Label>
                <Input
                  name='age'
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  onChange={handleChange}
                  value={editUserData.age ?? user?.age ?? ''}
                  required=""
                  disabled={!isEdit}
                  valid={isValid.age && isTouched.age}
                  invalid={!isValid.age && isTouched.age}
                />
                <FormFeedback valid>Age looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.age && isTouched.age).toString()}>Please enter a valid age.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{WeightText}</Label>
                <Input
                  name='weight'
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  onChange={handleChange}
                  value={editUserData.weight ?? user?.weight ?? ''}
                  required=""
                  disabled={!isEdit}
                  valid={isValid.weight && isTouched.weight}
                  invalid={!isValid.weight && isTouched.weight}
                />
                <FormFeedback valid>weight looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.name && isTouched.name).toString()}>Please enter a valid weight.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{HeightText}</Label>
                <Input
                  name='height'
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  onChange={handleChange}
                  value={editUserData.height ?? user?.height ?? ''}
                  required=""
                  disabled={!isEdit}
                  valid={isValid.height && isTouched.height}
                  invalid={!isValid.height && isTouched.height}
                />
                <FormFeedback valid>height looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.height && isTouched.height).toString()}>Please enter a valid height.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{GymFrequencyText}</Label>
                <Input
                  name='gymFrequency'
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  onChange={handleChange}
                  value={editUserData.gymFrequency ?? user?.gymFrequency ?? ''}
                  required=""
                  disabled={!isEdit}
                  valid={isValid.gymFrequency && isTouched.gymFrequency}
                  invalid={!isValid.gymFrequency && isTouched.gymFrequency}
                />
                <FormFeedback valid>Gym Frequency looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.gymFrequency && isTouched.gymFrequency).toString()}>Please enter a valid name.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{CalorieTargetText}</Label>
                <Input
                  name='calorieTarget'
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  onChange={handleChange}
                  value={editUserData.calorieTarget ?? user?.calorieTarget ?? ''}
                  required=""
                  disabled={!isEdit}
                  valid={isValid.calorieTarget && isTouched.calorieTarget}
                  invalid={!isValid.calorieTarget && isTouched.calorieTarget}
                />
                <FormFeedback valid>calorieTarget looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.calorieTarget && isTouched.calorieTarget).toString()}>Please enter a valid calorieTarget.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{Phone}</Label>
                <Input
                  name='phoneNumber'
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  onChange={handleChange}
                  value={editUserData.phoneNumber ?? user?.phoneNumber ?? ''}
                  required=""
                  disabled={!isEdit}
                  valid={isValid.phoneNumber && isTouched.phoneNumber}
                  invalid={!isValid.phoneNumber && isTouched.phoneNumber}
                />
                <FormFeedback valid>phoneNumber looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.phoneNumber && isTouched.phoneNumber).toString()}>Please enter a valid phoneNumber.</FormFeedback>
              </FormGroup>
              <div className="text-sm-end">
                {
                  user ?
                    isEdit
                      ? <div className="form-footer d-flex justify-content-between">
                        <Btn attrBtn={{ color: 'danger', onClick: () => handleCancelEdit(), disabled: loading ? loading : loading, }}>{loading ? 'LOADING...' : Cancel}</Btn>
                        <Btn attrBtn={{ color: 'secondary', onClick: () => handleUpdateUser(), disabled: loading ? loading : loading, }}>{loading ? 'LOADING...' : Save}</Btn>
                      </div>
                      : <div className="form-footer d-flex justify-content-between">
                        <Btn attrBtn={{ color: 'danger', onClick: () => setIsOpenModalDelete(true), disabled: loading ? loading : loading, }}>{loading ? 'LOADING...' : DeleteProfile}</Btn>
                        <Btn attrBtn={{ color: 'primary', onClick: () => setIsEdit(true), disabled: loading ? loading : loading, }}>{loading ? 'LOADING...' : EditProfile}</Btn>
                      </div>
                    : ''
                }
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {isOpenPhoto && (
        <Lightbox
          mainSrc={editUserData?.imageUrl ?? (user?.urlPhoto !== '' ? user?.urlPhoto : null) ?? man}
          onCloseRequest={() => setIsOpenPhoto(false)}
        />
      )}
      <Modal isOpen={isOpenModalDelete} toggle={() => setIsOpenModalDelete(!isOpenModalDelete)} centered>
        <ModalBody>
          Apakah anda yakin menghapusnya?
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: () => setIsOpenModalDelete(false) }} >{Cancel}</Btn>
          <Btn attrBtn={{ color: 'danger', onClick: () => handleDeleteUser() }}>{DeleteProfile}</Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default DetailUser;