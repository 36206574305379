import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import UserList from './UserList';
import { handleDisplayPages, handleIndexData } from '../../../_helper/Pages';
import { getAllUserServiceApi } from '../../../Services/Api.Service';
import { toast } from 'react-toastify';
import DetailUser from './DetailUser';

const UserManagementContain = () => {
  const [loading, setLoading] = useState(true);
  
  const [user, setUser] = useState(null);
  const [usersData, setUsersData] = useState([]);
  
  const [pageSize, setPageSize] = useState(10);

  
  const [pages, setPages] = useState({
    total: 0,
    active: 1,
    pageSize: 10,
    pagesToShow: 5,
    startPage: 0,
    endPage: 0,
    startIndex: 0,
    endIndex: 0
  });

  const getAllUser = async () => {
    try {
      await getAllUserServiceApi()
        .then(async (resp) => {
          switch (resp.data['statusCode']) {
            case 200:
              setUsersData(
                resp.data['data'].map(user => ({
                  id: user.id_user,
                  name: user.fullname,
                  email: user.email,
                  gender: user.jenis_kelamin,
                  age: user.umur,
                  weight: user.berat_badan,
                  height: user.tinggi_badan,
                  gymFrequency: user.frekuensi_gym,
                  calorieTarget: user.target_kalori,
                  urlPhoto: user.foto_url,
                  phoneNumber: user.no_telepon,
                }))
              );
              const totalPages = Math.ceil(resp.data['data'].length / pages.pageSize)
              const [startPage, endPage] = handleDisplayPages(1, pages.pagesToShow, totalPages);
              const [startIndex, endIndex] = handleIndexData(pages.active, pages.pageSize);
              setPages({
                ...pages,
                total: totalPages,
                startPage: startPage,
                endPage: endPage,
                startIndex: startIndex,
                endIndex: endIndex
              });
              setLoading(false);
              setUser(null);
              break;
            default:
              throw new Error(resp);
          }
        });
    } catch (error) {
      setLoading(false);
      toast.error('Gagal mendapatkan data, silahkan refresh.');
    }
  }

  useEffect(() => {
    getAllUser();
  }, [])

  useEffect(() => {
    getAllUser();
  }, [pageSize])

  const handlePageChange = page => {
    const [startIndex, endIndex] = handleIndexData(page, pageSize);

    setPages({
      ...pages,
      startIndex: startIndex,
      endIndex: endIndex
    });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    const [startIndex, endIndex] = handleIndexData(page, pageSize);

    setPages({
      ...pages,
      startIndex: startIndex,
      endIndex: endIndex,
      pageSize: newPerPage
    });
    setPageSize(newPerPage);
  }

  const handleDetailUser = (user) => {
    setUser(user);
  }

  return (
    <Fragment>
      <Container fluid={true} className="default-dash">
        <Row>
          <UserList
            handleDetailUser={handleDetailUser}
            pages={pages}
            loading={loading}
            usersData={usersData}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
          />
          <DetailUser
            user={user}
            getAllUser={getAllUser}
          />
        </Row>
      </Container>
    </Fragment>
  );
};
export default UserManagementContain;