export const handleDisplayPages = (currentPage, pagesToShow, totalPages) => {
    let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    if (totalPages - endPage < Math.floor(pagesToShow / 2)) {
        startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    return [startPage, endPage];
}

export const handleIndexData = (currentPage, pageSize) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return [startIndex, endIndex];
}