import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Form, FormFeedback, FormGroup, Input, InputGroup, Label } from 'reactstrap';
import { Btn, H4 } from '../../../AbstractElements';
import { Save, EditPassword, OldPassword, NewPassword, RetypePassword } from '../../../Constant';
import { Bounce, toast } from 'react-toastify';
import { updatePasswordServiceApi } from '../../../Services/Api.Service';

const EditPasswordWidget = () => {
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  const [values, setValues] = useState({
    oldPassword: '',
    newPassword: '',
    retypePassword: '',
  });

  const [isValid, setIsValid] = useState({
    oldPassword: false,
    newPassword: false,
    retypePassword: false,
  });

  const [isTouched, setIsTouched] = useState({
    oldPassword: false,
    newPassword: false,
    retypePassword: false,
  });

  const [isPasswordMatch, setIsPasswordMatch] = useState(false);

  const handleChangeSingleInput = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });

    setIsValid({
      ...isValid,
      [name]: value.length > 0 
    });

    setIsTouched({
      ...isTouched,
      [name]: value.length > 0 ? true : false
    });
  };

  useEffect(() => {
    setIsPasswordMatch(values.newPassword === values.retypePassword);
  }, [values])

  const handleEditPassword = async (e) => {
    setLoading(true);
    if (Object.values(isValid).every(value => value)) {
      try {
        const resp = await updatePasswordServiceApi(values);
        if (resp.data['statusCode'] !== 200) {
          throw resp;
        } else {
          toast.success('Password update successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } catch (error) {
        toast.error('Error update Password', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      setLoading(false);
    } else {
      toast.error('Please fill all', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{EditPassword}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <FormGroup className="mb-3">
              <Label className="form-label">{OldPassword}</Label>
              <InputGroup>
                <Input
                  name='oldPassword'
                  className="form-control"
                  id="exampleInputName"
                  type={togglePassword ? 'text' : 'password'}
                  onChange={handleChangeSingleInput}
                  value={values.oldPassword}
                  required=""
                  placeholder=""
                  valid={isValid.oldPassword && isTouched.oldPassword}
                  invalid={!isValid.oldPassword && isTouched.oldPassword}
                />
              </InputGroup>
              <FormFeedback valid>Password looks good!</FormFeedback>
              <FormFeedback invalid={(!isValid.oldPassword && isTouched.oldPassword).toString()}>Please enter a valid password.</FormFeedback>

            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{NewPassword}</Label>
              <Input
                name='newPassword'
                className="form-control"
                id="exampleInputName"
                type={togglePassword ? 'text' : 'password'}
                onChange={handleChangeSingleInput}
                value={values.newPassword}
                required=""
                placeholder=""
                valid={isValid.newPassword && isTouched.newPassword}
                invalid={!isValid.newPassword && isTouched.newPassword}
              />
              <FormFeedback valid>Password looks good!</FormFeedback>
              <FormFeedback invalid={(!isValid.newPassword && isTouched.newPassword).toString()}>Please enter a valid password.</FormFeedback>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{RetypePassword}</Label>
              <Input
                name='retypePassword'
                className="form-control"
                id="exampleInputName"
                type={togglePassword ? 'text' : 'password'}
                onChange={handleChangeSingleInput}
                value={values.retypePassword}
                required=""
                placeholder=""
                valid={isTouched.retypePassword && isPasswordMatch}
                invalid={isTouched.retypePassword && !isPasswordMatch}
              />
              <FormFeedback valid>Password Match!</FormFeedback>
              <FormFeedback invalid={(!isValid.retypePassword && isTouched.retypePassword).toString()}>Please enter a match password.</FormFeedback>
            </FormGroup>
            <div className="form-footer d-flex justify-content-between">
              <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'button', onClick: (e) => handleEditPassword(e), disabled: loading ? loading : loading, }}>{loading ? 'LOADING...' : Save}</Btn>
              <Btn attrBtn={{ className: 'btn-block', color: 'secondary', type: 'button', onClick: () => setTogglePassword(!togglePassword) }}>{togglePassword ? 'Hide' : 'Open'}</Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default EditPasswordWidget;